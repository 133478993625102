import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppThunkDispatch } from "redux/store"
import { useServerErrorHandler } from "hooks"
import { useToken } from "cookies"
import { getMessagesPath, getWelcomePath } from "routes"
import { Notification } from "types/notifications"
import { StyleProps } from "types/styles"
import { handleErrorCodes } from "utils/httpErrorCodes"
import { selectLanguageObject } from "pages/Login/LoginSlice"
import { selectOnlineState } from "pages/Impact/Impact.slice"
import {
  fetchNotifications,
  getError,
  getNotifications,
  updateNotification,
  updateMessage,
  selectIsLoading
} from "components/Messages/NotificationPopUp"
import useStyles from "./Messages.styles"
import back from "resources/icons/back.svg"
import cross from "resources/icons/cross.svg"
import {
  Container,
  ErrorComponent,
  HeaderContainer,
  Icon,
  Mailbox,
  MessageDetails,
  Offline,
  Title,
  Loader
} from "components"
import { useProgram } from '../../program'
import { ErrorDetails } from "types"


interface MessagesProps {
  message?: Notification,
  messageId?: string,
  style?: StyleProps,
}

const Messages: React.FC<MessagesProps> = (props) => {
  const {
    message,
    messageId,
  } = props

  const program = useProgram()
  const {token} = useToken()
  const handleServerError = useServerErrorHandler()
  const dispatch = useDispatch<AppThunkDispatch>()

  const dictionary = useSelector(selectLanguageObject)
  const error = useSelector(getError)
  const inbox = useSelector(getNotifications)

  const isLoading = useSelector(selectIsLoading);

  const classes = useStyles()

  const history = useHistory()

  const [title, setTitle] = useState('')
  const [notDone, setNotDone] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [refreshed, setRefreshed] = useState(false)
  const [displayedMessage, setDisplayedMessage] = useState<Notification | undefined>(message)
  const isOnline = useSelector(selectOnlineState)

  const [errorDetails, setErrorDetails] = useState({} as ErrorDetails)
  
  const refreshNotifications = useCallback(async () => {
    if (isOnline && token && !refreshing && !refreshed) {
      setRefreshing(true)
      const res = (await handleServerError(dispatch(fetchNotifications({token}))))
      if (res.meta.requestStatus === 'fulfilled') {
        setRefreshed(true) 
        return
      }
    }
  }, [isOnline, token, refreshing, refreshed, handleServerError, dispatch])

  refreshNotifications();


  useEffect(() => {
    if (inbox) {
      const unreadMessages = inbox.messages.filter((message: Notification) => message.hasBeenRead === 0).length
      const title = unreadMessages > 0 ? `${dictionary.myBox} (${unreadMessages})` : dictionary.myBox
      setTitle(title)
    }
  }, [inbox, dictionary])


  useEffect(() => {
    if(error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, dictionary.connectionError))
    }
  }, [error, dictionary.connectionError])


  useEffect(() => {
    if (token && refreshed && messageId && notDone)
    {
      const notifToDisplay = inbox.messages.filter(notification => notification.id.toString() === messageId)[0]
      setDisplayedMessage(notifToDisplay);

      setNotDone(false);
      if ((notifToDisplay !== null) && (notifToDisplay !== undefined)) {
        const id = notifToDisplay.id,
            body = {
              programId: parseInt(program.id)
            },
            key = "hasBeenRead",
            value = 1
        
      handleServerError(dispatch(updateNotification({token, id, body})))
      dispatch(updateMessage({id, key, value}))  
      }
    }
 
}, [dispatch, handleServerError, token, refreshed, messageId, inbox, program.id, notDone])

  const handleBack = () => {
    if(displayedMessage) {
      setDisplayedMessage(undefined)
      history.push(getMessagesPath(program.name))
    } else {
      history.goBack()
    }
  }

  const handleClick = (notification: Notification) => {
    setDisplayedMessage(notification)
    const id = notification.id,
        body = {
          programId: parseInt(program.id)
        },
        key = "hasBeenRead",
        value = 1
    handleServerError(dispatch(updateNotification({token, id, body})))
    dispatch(updateMessage({id, key, value}))
  }

  const handleClose = () => {
    history.push(getWelcomePath(program.name))
  }

  if (isOnline && isLoading) return <Loader/>

  return(
    errorDetails.message
    ? <ErrorComponent message={errorDetails.message} path={getWelcomePath(program.name)} />
    : <Offline content={inbox}>
      <HeaderContainer className={classes.headContainer}>
        <Icon onClick={handleBack} className={classes.icon} imageUrl={back} />
        <Title title={title} />
        <Icon onClick={handleClose} className={classes.icon} imageUrl={cross} />
      </HeaderContainer>
      <Container variant="contentWithFixedHeader" style={{align:"center"}}>
        {
          displayedMessage
          ? <MessageDetails message={displayedMessage} />
          :<Mailbox messages={inbox?.messages} onClick={handleClick} activeTab={"messages"} />


        }
      </Container>
    </Offline>
  )
}

export default Messages
