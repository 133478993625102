import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
  
    return createStyles({
        button: {
            background: theme.palette.secondary.light,
            width: "17rem",
            // marginTop: "1rem",
            color: theme.palette.primary.main,
            display: "flex-start",
            justifyContent: "space-between",
    //        alignItems: "center",
          //  textAlign: "match-parent"
        },
        smallButton: {
          background: theme.palette.secondary.light,
      //    display: "flex-start",
     //     right: "4.5rem",
          marginBottom: "1rem",
          color: theme.palette.primary.main, 
          fontSize: "0.70rem",  
         // width: "18rem !important",
      //   textAlign: "center",
         justifyContent: "flex-start",
       //   alignSelf: "center",
         // justifyContent: "space-between",
        //  paddingLeft: '0.4em',
         // paddingRight: '0.4em',
      //   alignItems: "center",
        },
        popupTitle: {
          display: "flex-start",
          alignItems: "center",
          textAlign: 'center',  
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
        },
        dialog: {
          display: "flex-start",
          alignItems: "center",
          textAlign: 'center',  
          paddingLeft: '0.4em',
          paddingRight: '0.4em',
        },
        text: {
          font: theme.typography.fontFamily,
          fontSize: "1rem",
          color: theme.palette.primary.main,
          textAlign: "center",
          minWidth: "16.875rem",
          padding: "0.625rem"
      },
      smallText: {
          color: theme.palette.primary.main,
          padding: "0.725rem",
          minWidth: "unset"
      },
      iconContainer:{
        display: "flex", 
        flexDirection : "row-reverse",
        paddingTop :"10px",
        paddingRight :"10px"
      }
    })
})