import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ErrorType} from 'types';
import {handleError} from '../../../utils/httpErrorCodes'
import {Notification} from 'types/notifications';
import {getService, putService} from 'api';
import {AppState} from 'redux/rootReducer';

interface UserParams {
  token: string
}

interface UpdateNotif {
  token: string,
  id: number,
  body: {
    programId: number,
    archived?: boolean
  },
}

interface NotificationsState {
  notifications: Notification[],
  messages: Notification[],
  isLoading: boolean,
  error: ErrorType | null,
  showSuccessInfo: boolean
}

const initialState: NotificationsState = {
  isLoading: false,
  error: null,
  showSuccessInfo: false,
  notifications: [],
  messages: []
}

export const fetchNotifications: AsyncThunk<Notification, UserParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'notifications',
  async ({token}: UserParams, {rejectWithValue}) => {
    try {
      return await getService(token, 'notifications');
    } catch (error: any) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const updateNotification:  AsyncThunk<any, UpdateNotif, { rejectValue: ErrorType }> = createAsyncThunk(
  'notifications/update',
  async ({token, id, body }: UpdateNotif, {rejectWithValue}) => {
    try {
      return await putService(token, `notifications/${id}`, body);
    } catch (error: any) {
      return rejectWithValue(handleError(error))
    }
  }
)

export const getNotifications = (state: AppState) => {
  return state.notifications
}

export const getError = (state: AppState): ErrorType | null => {
  const {error} = state.notifications
  return error
}

const NotificationPopUpSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    createNotification: (state, action) => {
      state.notifications.push({
        id: action.payload.id,
        sender: action.payload.sender,
        title: action.payload.title,
        body: action.payload.body,
        level: action.payload.level,
        type: action.payload.type,
        sentDate: action.payload.sentDate,
        hasBeenNotified: action.payload.hasBeenNotified,
        hasBeenRead: action.payload.hasBeenRead,
        archived: action.payload.archived
      })
    },
    updateMessage: (state, action) => {
      state.messages = state.messages.map(message => {
        if (message.id === action.payload.id) {
          return Object.assign({}, message, { 
            [action.payload.key]: action.payload.value 
          })
        }
        return message
      })
    },
    removeNotifications: (state) => {
      state.notifications = []
    }
  },
  extraReducers: {
    [fetchNotifications.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchNotifications.fulfilled.type]: (state, {payload}: PayloadAction<[Notification]>) => {
      state.messages = payload
      state.notifications = payload.filter((message) => message.hasBeenNotified !== 1)
      state.isLoading = false
      state.error = null
    },
    [fetchNotifications.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    },
    [updateNotification.fulfilled.type]: (state, {payload}: PayloadAction<any>) => {
      state.isLoading = false
      state.error = null
    },
    [updateNotification.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    }
  }
})

export const {createNotification, updateMessage, removeNotifications} = NotificationPopUpSlice.actions

export const selectIsLoading = (state: AppState): boolean => {
  return state.notifications.isLoading
}
export default NotificationPopUpSlice.reducer