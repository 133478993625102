import { FC, useState, useCallback, useMemo } from 'react'
import { useToken } from 'cookies'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppThunkDispatch } from 'redux/store'
import { selectOnlineState } from 'pages/Impact/Impact.slice'
import { selectLanguageObject } from 'pages/Login/LoginSlice'
import { UserProfile } from 'types/userProfile'
import { useProgram } from 'program'
import { putService } from 'api'
import { PhoneRules } from 'constants/profile'
import { updateFarmData } from './../FarmSlice'
import {
    setProfileState,
    getProfileState,
} from './../ProfileSlice'
import { waitToUpdateState } from 'utils/utils'
import {
    HeaderContainer,
    Title,
    Icon,
    Container,
    Button,
    Popup,
    Snackbar,
    Speech
} from 'components'
import { Avatar, Typography } from '@material-ui/core'
import back from 'resources/icons/back.svg'
import useStyles from './ProfileEdit.styles'
import PhoneNumberInput from './PhoneNumber'


const ProfileEdit: FC<{}> = () => {
    const { token } = useToken()
    const history = useHistory()
    const program = useProgram()
    const language = useSelector(selectLanguageObject)
    const profileState = useSelector(getProfileState)
    const dispatch = useDispatch<AppThunkDispatch>()
    const classes = useStyles()

    const dictionary = useSelector(selectLanguageObject)
    const isOnline = useSelector(selectOnlineState)


    const userProfile = (history.location.state as UserProfile)
    const originalData = userProfile

    const [profileData, setProfileData] = useState(userProfile)
    const [phoneError, setPhoneError] = useState(false)
    const [formErrors, setFormErrors] = useState({
        phoneNumber: '',
    })

    const [offlineError, setOfflineError] = useState(false)

    const [disabled, setDisabled] = useState(false)
    const [showUpdateNotification, setShowUpdateNotification] = useState(false)
    const [openDialogGPS, setOpenDialogGPS] = useState(false)

    const phonePrefix = program.configs.phonePrefix
    const fieldsValidators = useMemo(() => {
        return ({
            phoneNumber: {
                phoneMinDigits: PhoneRules.minDigits - phonePrefix.length,
                phoneMaxDigits: PhoneRules.maxDigits - phonePrefix.length, 
            }
        })
    }, [phonePrefix.length])


    const validateField = useCallback(async (key: string, val: string) => {
        switch (key) {
            case 'phoneNumber':
                // Verify number of digit
                if (val.length <= fieldsValidators[key].phoneMinDigits || val.length >= fieldsValidators[key].phoneMaxDigits) {
                    setPhoneError(true)
                    setFormErrors(prevState => ({
                        ...prevState,
                        [key]: dictionary.phoneNumberError
                            .replace("$minQuantity", PhoneRules.minDigits.toString())
                            .replace("$maxQuantity", PhoneRules.maxDigits.toString())
                    }))
                // Verify if there is hyphens
                }else if(val.includes("-")){
                    setPhoneError(true)
                    setFormErrors(prevState => ({
                        ...prevState,
                        [key]: dictionary.phoneNumberErrorHyphens
                    }))
                }else{
                    setPhoneError(false)
                    return setFormErrors(prevState => ({ ...prevState, [key]: '' }))
                }
                break
            default:
                return true
        }
    }, [fieldsValidators, dictionary.phoneNumberError, dictionary.phoneNumberErrorHyphens])


    const renderAvatar = (imageUrl: string | undefined, title: string) => {
        return imageUrl
            ? <Avatar
                className={classes.avatar}
                src={imageUrl}
                alt={title}
            />
            : <Avatar className={classes.avatar}>
                {title ? title.toUpperCase() : ''}
            </Avatar>
    }

    const showErrorGPS = (error: GeolocationPositionError) => {
        console.log('Geolocation error:', error.message)
    }

    const handleGPSCoordinates = () => {
        const success = (position: GeolocationPosition) => {
            const crd = position.coords
            const farmData = {
                gpsPoint: {
                    type: "Point",
                    coordinates: [crd.longitude, crd.latitude]
                },
                id: profileState?.farmId
            }
            dispatch(updateFarmData({ token, farmData }))
            if (profileState) {
                const newProfileState = {...profileState }
                newProfileState.gpsPoint = {
                    type: "Point",
                    coordinates: [crd.longitude, crd.latitude]
                };
                dispatch(setProfileState(newProfileState))
            }

        }
        navigator.geolocation.getCurrentPosition(success, showErrorGPS)
    }

    const renderDialogGPS = () =>
    (
        <Popup
            title={language.confirmLocation}
            buttonLabel={language.goGPS}
            explanationLabel={language.redirectDisclaimer}
            open={openDialogGPS}
            onClose={() => setOpenDialogGPS(false)}
            onSubmit={() => { 
                handleGPSCoordinates() 
                setOpenDialogGPS(false) 
            }}
            onSubmitWithExtraAction={() => null}
        />
    )

    const updateProfileData = useCallback(async () => {
        setDisabled(true)
        if (isOnline && token) {
            const newProfileData = Object.entries(profileData).filter(([key, value]) => !Object.values(originalData).includes(value)),
                newProfileDataObject = Object.fromEntries(newProfileData)

            if (newProfileDataObject.hasOwnProperty('phoneNumber')) {
                newProfileDataObject.phoneNumber = phonePrefix + newProfileDataObject.phoneNumber
            }

            if (newProfileData.length) {
                const isValid = Object.values(formErrors).every(val => val === '')

                if (isValid) {
                    const url = 'user/updateProfileData'
                    try {
                        await (putService(token, url, newProfileDataObject))

                        setShowUpdateNotification(true)
                        waitToUpdateState(setShowUpdateNotification, false, 5000)
                    } catch (error) {
                        console.log('Error occurred when attempting to update profile data:')
                        console.log(error)
                    }
                }
            }
        } else {
            setOfflineError(true)
            waitToUpdateState(setOfflineError, false, 5000)
        }
        setDisabled(false)
    }, [isOnline, token, profileData, originalData, phonePrefix, formErrors])



   
    return (
        <div>
        <HeaderContainer className={classes.headerContainer}>
            <Icon
                onClick={() => history.goBack()}
                className={classes.icon}
                imageUrl={back}
            />
            <Title title={dictionary.editProfile} />
        </HeaderContainer>

        <div className={classes.profileContainer}>
            <div className={classes.profileHeader}>
                <div className={classes.avatarContainer}>
                    {
                        renderAvatar(
                            profileData?.photo,
                            profileData ? profileData.lastName : ''
                        )
                    }
                </div>
            </div>

            <div className={classes.sectionContainer}>
                <Speech text={dictionary.profileInfos}>
                    <div className={classes.sectionTitleContainer}>
                        <Typography
                            className={classes.sectionTitle}
                        >
                            {dictionary.profileInfos}
                        </Typography>
                    </div>
                </Speech>
                <Container className={classes.profileForm}>
                    <PhoneNumberInput 
                    profileData={profileData} 
                    setProfileData={setProfileData} 
                    validateField={validateField} 
                    phoneError={phoneError} 
                    formErrors={formErrors}
                    phonePrefix={phonePrefix}
                    size="small"
                    className={classes.input}
                    variant='outlined'
                    label={dictionary.phone}
                    />
                    </Container>
                </div>
                <div className={classes.sectionContainer}>
                    <div className={classes.formContainer}>
                        <div className={classes.formCol}>
                            <Speech text={language.GPS}>
                                <div className={classes.inputLabel}>
                                    {language.GPS}
                                </div>
                            </Speech>
                            <Speech text={`${profileState?.gpsPoint.coordinates[0]}, ${profileState?.gpsPoint.coordinates[1]}}`}>
                                <div className={classes.inputGps}>
                                    {profileState?.gpsPoint.coordinates[0]}, {profileState?.gpsPoint.coordinates[1]}
                                </div>
                            </Speech>
                        </div>
                        <div className={classes.sectionTitleContainer}>
                            <Speech text={language.editGPS}>
                            <Button
                                label={language.editGPS}
                                onClick={() => setOpenDialogGPS(true)}
                                className={classes.editButton}
                                disabled={!isOnline}
                            />
                            </Speech>
                        </div>
                    </div>
                </div>
                {renderDialogGPS()}

                <Button
                    label={dictionary.save}
                    onClick={() => updateProfileData()}
                    disabled={disabled}
                    className={classes.button}
                />
                {
                    offlineError &&
                    <div className={classes.error}>
                        {dictionary.internetConnection}
                    </div>
                }
                {
                    showUpdateNotification &&
                    <Snackbar
                        open={showUpdateNotification}
                        message={dictionary.updated}
                        onClose={() => setShowUpdateNotification(false)}
                    />
                }
                </div>
                </div>

    )
}

export default ProfileEdit